<template>
  <v-dialog
    scrollable
    v-model="dialog"
    max-width="600px"
    @click:outside="resetForm()"
  >
    <v-card>
      <v-card-title class="headline"
        >{{ isEditing ? "Edit" : "Add a new" }} Record</v-card-title
      >
      <v-divider></v-divider>
      <v-card-text>
        <v-form @submit.prevent="saveForm" method="post" id="record-form">
          <v-text-field
            label="Date"
            v-model="fields.date"
            type="date"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('date')"
            :error-messages="errors['date']"
          ></v-text-field>

          <v-file-input
            label="Document"
            v-model="fields.document"
            accept="application/pdf"
            placeholder="Choose a File"
            outlined
            @change="fileHasChanged()"
            :error="errors.hasOwnProperty('document')"
            :error-messages="errors['document']"
          ></v-file-input>

          <v-textarea
            label="Comments"
            v-model="fields.comments"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('comments')"
            :error-messages="errors['comments']"
          ></v-textarea>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="resetForm()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="record-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      fields: {
        comments: null,
        date: null,
        document: null,
        file_has_changed: false,
      },
      dialog: false,
      loading: false,
      isEditing: false,
      errors: {},
    };
  },

  methods: {
    fileHasChanged: function () {
      this.fields.file_has_changed = true;
    },

    openForm: function (record = null) {
      if (record !== null) {
        this.record = record;
        this.fields.comments = record.comments;
        this.fields.date = record.date;

        this.fields.document = record.document
          ? new File([record.document], record.document)
          : null;

        this.isEditing = true;
      }

      this.dialog = true;
    },

    saveForm() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        dogId: this.$route.params.dogId,
        isEditing: this.isEditing,
        fields: this.fields,
      };

      if (this.isEditing) {
        payload.recordId = this.record.id;
      }

      this.$store
        .dispatch("lhl/dogs/saveRecord", payload)
        .then(() => {
          this.resetForm();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    resetForm: function () {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;

      this.fields.comments = null;
      this.fields.date = null;
      this.fields.document = null;
      this.fields.file_has_changed = false;
    },
  },
};
</script>
