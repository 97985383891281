<template>
  <v-dialog
    scrollable
    v-model="dialog"
    max-width="600px"
    @click:outside="resetForm()"
  >
    <v-card>
      <v-card-title class="headline"
        >{{ isEditing ? "Edit" : "Add a new" }} Health Medication
        Record</v-card-title
      >
      <v-divider></v-divider>
      <v-card-text>
        <v-form @submit.prevent="saveForm" method="post" id="medication-form">
          <v-text-field
            label="Date"
            v-model="fields.date"
            type="date"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('date')"
            :error-messages="errors['date']"
          ></v-text-field>

          <v-select
            label="Medication Product"
            v-model="fields.medication_product_id"
            :items="medications"
            item-text="name"
            item-value="id"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('medication_product_id')"
            :error-messages="errors['medication_product_id']"
          ></v-select>

          <v-text-field
            label="Weight"
            v-model="fields.weight"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('weight')"
            :error-messages="errors['weight']"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="resetForm()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="medication-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      fields: {
        date: null,
        medication_product_id: null,
        weight: null,
      },
      dialog: false,
      loading: false,
      isEditing: false,
      errors: {},
      medication: {},
    };
  },

  computed: {
    medications() {
      return this.$store.getters["lhl/dogs/medications_products"].filter(
        (c) => c.type === "medication"
      );
    },
  },

  methods: {
    openForm: function (medication = null) {
      if (medication !== null) {
        this.medication = medication;
        this.fields.date = medication.date;

        this.fields.medication_product_id = medication.product.id;
        this.fields.weight = medication.weight;

        this.isEditing = true;
      }

      this.dialog = true;
    },

    saveForm() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        dogId: this.$route.params.dogId,
        isEditing: this.isEditing,
        fields: this.fields,
      };

      if (this.isEditing) {
        payload.medicationId = this.medication.id;
      }

      this.$store
        .dispatch("lhl/dogs/saveMedication", payload)
        .then(() => {
          this.resetForm();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    resetForm: function () {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;

      this.fields.date = null;
      this.fields.weight = null;
      this.fields.medication_product_id = null;

      this.errors = {};
    },
  },
};
</script>
