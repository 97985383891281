<template>
  <v-dialog
    scrollable
    v-model="dialog"
    max-width="600px"
    @click:outside="resetForm()"
  >
    <v-card>
      <v-card-title class="headline">Edit Defects</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form @submit.prevent="saveForm" method="post" id="defect-form">
          <v-switch
            label="Base Narrow Canines"
            v-model="fields.base_narrow_canines"
            inset
            :error="errors.hasOwnProperty('base_narrow_canines')"
            :error-messages="errors['base_narrow_canines']"
          ></v-switch>
          <v-switch
            label="Umbilical Hernia"
            v-model="fields.umbilical_hernia"
            inset
            :error="errors.hasOwnProperty('umbilical_hernia')"
            :error-messages="errors['umbilical_hernia']"
          ></v-switch>
          <v-switch
            label="Heart Murmur"
            v-model="fields.heart_murmur"
            inset
            :error="errors.hasOwnProperty('heart_murmur')"
            :error-messages="errors['heart_murmur']"
          ></v-switch>
          <v-switch
            label="Underbite"
            v-model="fields.underbite"
            inset
            :error="errors.hasOwnProperty('underbite')"
            :error-messages="errors['underbite']"
          ></v-switch>
          <v-switch
            label="Overbite"
            v-model="fields.overbite"
            inset
            :error="errors.hasOwnProperty('overbite')"
            :error-messages="errors['overbite']"
          ></v-switch>
          <v-switch
            label="Open Face"
            v-model="fields.open_face"
            inset
            :error="errors.hasOwnProperty('open_face')"
            :error-messages="errors['open_face']"
          ></v-switch>

          <v-textarea
            label="Comments"
            v-model="fields.comments"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('comments')"
            :error-messages="errors['comments']"
          ></v-textarea>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="resetForm()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="defect-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      fields: {
        comments: null,
        base_narrow_canines: false,
        umbilical_hernia: false,
        heart_murmur: false,
        underbite: false,
        overbite: false,
        open_face: false,
      },
      dialog: false,
      loading: false,
      errors: {},
    };
  },

  methods: {
    openForm: function (defect = null) {
      if (defect !== null) {
        this.defect = defect;
        this.fields.base_narrow_canines = defect.base_narrow_canines;
        this.fields.umbilical_hernia = defect.umbilical_hernia;
        this.fields.heart_murmur = defect.heart_murmur;
        this.fields.underbite = defect.underbite;
        this.fields.overbite = defect.overbite;
        this.fields.comments = defect.comments;
        this.fields.open_face = defect.open_face;
      }

      this.dialog = true;
    },

    saveForm() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        dogId: this.$route.params.dogId,
        fields: this.fields,
      };

      this.$store
        .dispatch("lhl/dogs/saveDefect", payload)
        .then(() => {
          this.resetForm();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    resetForm: function () {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;

      this.fields.comments = null;
      this.fields.base_narrow_canines = false;
      this.fields.umbilical_hernia = false;
      this.fields.heart_murmur = false;
      this.fields.underbite = false;
      this.fields.overbite = false;
      this.fields.open_face = false;
    },
  },
};
</script>
